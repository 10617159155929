export const AUTH_TOKEN = 'Authorization';

export const LOCAL_STORAGE = {
  ID_TOKEN: 'token',
  USER: 'user',
};

export const TITLES = {
  WEBSITE_TITLE: 'Streameast Soccer',
}

export const MESSAGES = {
  EMAIL_SUCCESS: 'Your email has been sent successfully!',
  EMAIL_FAILURE: 'Unable to sent email!',
  ADD_STREAM_SUCCESS: 'Player added successfully!',
  ADD_STREAM_FAILURE: 'Failed to add player!',
  UPDATE_STREAM_SUCCESS: 'Streams updated successfully!',
  UPDATE_STREAM_FAILURE: 'Failed to add sreeams!',
  AUTH_SUCCESS: 'User Authenticated!',
  AUTH_FAILURE: 'Invalid user or password!'
}

export const categories = [
  {
    label: 'Soccer',
    value: 'Streams today'
  },
  {
    label: 'F1',
    value: 'F1'
  },
  {
    label: 'Tennis',
    value: 'tennis'
  },
  {
    label: 'Boxing',
    value: 'Boxing'
  },
  {
    label: 'UFC',
    value: 'UFC'
  },
  {
    label: 'Motogp',
    value: 'motogp'
  },
  {
    label: 'NFL',
    value: 'NFL'
  },
  {
    label: 'NBA',
    value: 'NBA'
  },
  {
    label: 'Rugby',
    value: 'Rugby'
  },
];

export const ADS = {
  MAIN: {
    TYPE: 'main',
    SLOT: '2554300823'
  },
  FIXTURE_LIST_DISPLAY_HORIZONTAL: {
    TYPE: 'FixtureListDisplayHorizontal',
    SLOT: '3824669050'
  },
  PLAYER_DISPLAY_HORIZONTAL: {
    TYPE: 'playerDisplayHorizontal',
    SLOT: '3744778007'
  },
  VERTICLE_MAIN: {
    TYPE: 'vertical',
    SLOT: '2926488530'
  },
  IN_ARTICLE: {
    TYPE: 'in-article',
    SLOT: '6685754230'
  },
  IN_FEED: {
    TYPE: 'in-feed',
    SLOT: '2184823320'
  },
  SQUARE_DISPLAY: {
    TYPE: 'square-display',
    SLOT: '7807264218'
  },
}

export const DEFAULT_LEAGUE_LINK = '/leagues/Premier League/645bec5ba42012fa6eb37334';
export const DEFAULT_TEAM_LINK = '/teams/Liverpool/6455eab25477a7f3d047a4cc';
