import { useEffect, useState } from 'react';
import Loader from '../../components/common/Loader';
import { getLeagueFixtures, getTeamFixtures } from '../../apis/fixture.api';
import { Col, Row, Table, Typography} from 'antd';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import Teams from '../Teams';
import { useParams } from 'react-router-dom';
// import AdSenseBanner from '../Ads';
// import { ADS } from '../../utils/constant';

interface LeagueInterface {
    leagueName: string;
    leagueImage: string;
    fixtures: any;
}

interface TeamInterface {
    teamName: string;
    teamImage: string;
    fixtures: any;
}

export default function FixtureList() {
    const { id, teamName, leagueName } = useParams();
    const [team, setTeam] = useState<TeamInterface>();
    const [league, setLeague] = useState<LeagueInterface>();
    const [loader, setLoader] = useState<boolean>(false);
    const matches = useMediaQuery('(min-width: 768px)');

    const columns = [
        {
            title: 'Team',
            dataIndex: 'teamA',
            key: 'teamA',
        },
        {
            title: 'Opponent',
            dataIndex: 'teamB',
            key: 'teamB',
        },
        {
            title: 'Date',
            dataIndex: 'fixtureDate',
            key: 'fixturDate',
        },
        {
            title: 'Time',
            dataIndex: 'fixtureTime',
            key: 'wins',
        },
    ];

    const mobileColumns = [
        {
            title: 'Team',
            dataIndex: 'teamA',
            key: 'teamA',
        },
        {
            title: 'Opponent',
            dataIndex: 'teamB',
            key: 'teamB',
        },
        {
            title: 'Date',
            dataIndex: 'fixtureDate',
            key: 'fixtureDate',
        },
        {
            title: 'Time',
            dataIndex: 'fixtureTime',
            key: 'fixtureTime',
        },
    ];

    const fixtureColumns = matches ? columns: mobileColumns;

    const getLeague = async ()=> {
        const response = await getLeagueFixtures({leagueName: leagueName});
        setLeague(response?.data);
    }

    const getTeam = async ()=> {
        const response = await getTeamFixtures({teamName: teamName});
        setTeam(response?.data);
    }

    const getTeamOrLeague = ()=> {
        setLoader(true);
        if(teamName){
            getTeam();
        } else {
            getLeague();
        }
        setLoader(false);
    }

    useEffect(() => {
        getTeamOrLeague()
        
    }, [id]);

    return (
        <>
            {<Teams></Teams>}
            <div style={{justifyContent: 'center', marginTop: '12px'}}>
                <img 
                src={leagueName ? league?.leagueImage : team?.teamImage} 
                alt={leagueName ? league?.leagueName : team?.teamName} 
                />
                <Typography.Title level={4} style={{color: 'white'}}>{leagueName ? league?.leagueName : team?.teamName} Fixtures</Typography.Title>
            </div>
            <Row gutter={24} style={matches ? {display: 'flex',justifyContent: 'center'}: {}}>
                <Col span={16}>
                    {/* <AdSenseBanner slot={ADS.FIXTURE_LIST_DISPLAY_HORIZONTAL.SLOT} type={ADS.FIXTURE_LIST_DISPLAY_HORIZONTAL.TYPE} /> */}
                    <Table dataSource={leagueName ? league?.fixtures: team?.fixtures} columns={fixtureColumns} />;
                </Col>
                {/* <Col span={4}>
                    <AdSenseBanner slot={ADS.VERTICLE_MAIN.SLOT} type={ADS.VERTICLE_MAIN.TYPE} />
                </Col> */}
            </Row>
            {loader ? <Loader /> : <></>}
        </>

    );
}
  