import { createStyles } from './utils';

export const styles = createStyles({
    flexDisplay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    logo:{
        display:'flex',
        justifyContent: 'center', 
        padding:'20px'
    },
    card: {
        paddingBottom:'20px',
        height: '95%',
    },
    paragraph: {
        paddingTop:'10px'
    },
    gridStyle: {
        width: '25%',
        textAlign: 'center',
    },
    homeButton: {
        marginLeft: '5px',
        marginRight: '5px',
        background:'#95100d',
        color: 'white',
        height: '40px',
        borderRadius: '5px',
    },
    imgWidth: {
        width: '100px',
    }
});